import React from 'react'
import { Box, Text, Heading } from '@primer/components'

import PageSetup from '../components/layout/PageSetup'
import LinkLight from '../components/reusable/LinkLight'

const Paragraph = ({ children }) => (
  <Text as="p" fontSize={3}>
    {children}
  </Text>
)

const AboutPage = () => (
  <PageSetup siteTitle="About">
    <Box maxWidth={['medium']} mt={12} mb={8}>
      <Box as="header">
        <Heading as="h1" fontSize={[5, 6]}>
          About
        </Heading>
      </Box>

      <Paragraph>
        Hi, I'm Francesco — a Security Specialist and digital creator based in New
        Zealand. A passion for digital technology and creativity drives my work.
      </Paragraph>
      <Paragraph>
        My early ambition was to become a VFX artist producing visual effects
        for movies. Not long into my studies, I realised how enjoyable
        computer science is. I have the most fun when I merge design and computer 
        science together.
      </Paragraph>
    </Box>
  </PageSetup>
)

export default AboutPage
